import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import "./style/Navbar.css"
import ModalAuth from "./ModalAuth";
const Navbarr = (props) => {
  const [showModal,setShowModal] = useState(false)
  const clickNavbar = () => {
    setShowModal(true)
  }

  return (
<>
<header className="header">
    <Container >
      <Row>
        <Col md={8} xs={12}>
          <div className="logo">
            <img src="https://valckpd.cl/wp-content/uploads/2022/03/LOGO-1.png" alt="" />
          </div>
        </Col>
        <Col md={2} xs={6} className="button">
         <div className="contador-imagenes">
           <p> {props.cantidadFotos}/{props.cFotos}</p>
         </div>
        </Col>
        <Col md={2} xs={6} className="button">
          <button onClick={clickNavbar}>Agregar al carrito</button>
        </Col>
      </Row>
    </Container>
{(showModal)&& <ModalAuth s={props.s} email={props.setEmail}/>}
    </header>  

</>


 )
}
export default Navbarr;