const firebaseConfig = {
  apiKey: "AIzaSyC9ufLQTnkQvZ11ynKqlhMOQmP1UOyDSkE",
  authDomain: "valck-producciones-digitales.firebaseapp.com",
  projectId: "valck-producciones-digitales",
  storageBucket: "valck-producciones-digitales.appspot.com",
  messagingSenderId: "796489336690",
  appId: "1:796489336690:web:496e77ee8b3c29033dbfeb",
  measurementId: "G-HSJRNS963D"
};
  

export default firebaseConfig;