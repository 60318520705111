import { useState } from "react";
import {  Modal } from 'react-bootstrap'
// import IconCamera from "./icons/IconCamera";
import DropArea from './Dropzone';
import './style/ModalDropZone.css'

const ModalDropZone = (props) => {
    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const tittle = <h2>Subir fotos</h2>
    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);
    var cFotos = urlParams.get('cFotos') || 0;

    const unirFotos = files => {
        let image = [];
        if (files.length <= cFotos) {
          for (var i = 0; i < files.length; i++) {
            image.push({ id: `${files[i].name}_${new Date().getTime()}`, file: files[i], url: URL.createObjectURL(files[i]) })
          } 
          props.unirImagenes(image)
        }
      }

    return (
        <>
            <div onClick={handleShow}>
                <div className="add-photo-button">
                    {/* <IconCamera width={50} fill='#fff' /> */}
                    <p className="add-photo-button-text">Subir fotos</p>
                </div>
            </div>

            <Modal size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{tittle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DropArea unirFotos={unirFotos} setcantidadFotos={props.setcantidadFotos} cantidadFotos={props.cantidadFotos} imagesLength={props.imagesLength}/>
                </Modal.Body>
                <Modal.Footer>
                   
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalDropZone;