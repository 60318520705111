
const IconDelete = props => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M66.744 97.5H33.261a15.85 15.85 0 0 1-15.837-15.826V39.133a16.031 16.031 0 0 1 .321-3.154 3.849 3.849 0 0 1 3.776-3.094h56.958a3.841 3.841 0 0 1 3.765 3.049 15.262 15.262 0 0 1 .332 3.2v42.54A15.847 15.847 0 0 1 66.744 97.5ZM25.127 40.587v41.087a8.138 8.138 0 0 0 8.134 8.126h33.483a8.136 8.136 0 0 0 8.129-8.123v-41.09Z" />
    <path d="M89.842 40.587H10.158a3.852 3.852 0 0 1-3.852-3.851V20.5a3.852 3.852 0 0 1 3.852-3.851h79.684a3.852 3.852 0 0 1 3.852 3.851v16.236a3.852 3.852 0 0 1-3.852 3.851Zm-75.833-7.7H85.99v-8.53H14.009Z" />
    <path d="M61.971 24.355H38.456A3.853 3.853 0 0 1 34.6 20.5V6.352A3.852 3.852 0 0 1 38.456 2.5h23.515a3.854 3.854 0 0 1 3.852 3.852V20.5a3.854 3.854 0 0 1-3.852 3.855Zm-19.664-7.7h15.812V10.2H42.307ZM35.834 55.832h7.702v18.726h-7.702zM56.465 55.832h7.701v18.726h-7.701z" />
  </svg>

)

export default IconDelete
