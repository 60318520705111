import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, setDoc, doc } from 'firebase/firestore/lite';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import firebaseConfig from './credentials/firebaseConfig'

console.log("firebase on")
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
//database
const db = getFirestore(app);

//cloudStorage
const storage = getStorage();
const storageRef = ref(storage);

// var cargaImagen = (id, file,idDocumento) => {
//   const storage = getStorage();
//   const storageRef = ref(storage, idDocumento+'/'+id);
//   const uploadTask = uploadBytesResumable(storageRef, file);
//   uploadTask.on('state_changed',
//   (snapshot) => {
//     const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//     console.log('Upload is ' + progress + '% done');
//     switch (snapshot.state) {
//       case 'paused':
//         console.log('Upload is paused');
//         break;
//       case 'running':
//         console.log('Upload is running');
//         break;
//     }
//   },
//   (error) => {
//     // Handle unsuccessful uploads
//   },
//   () => {
//     // Handle successful uploads on complete
//     // For instance, get the download URL: https://firebasestorage.googleapis.com/...
//     getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
//       console.log('File available at', downloadURL);
//     });
//   }
// );
// var imageRef = ref(storage, "test/" + id);
// uploadBytes(imageRef, file).then((snapshot) => {
//   console.log('Subid0');
// });

// }

const cargaImagen = async (id, file, idDocumento) => {
  const storage = getStorage();
  const storageRef = ref(storage, idDocumento + '/' + id);
  
  await uploadBytesResumable(storageRef, file);
  return id;
}


var cargaDocumento = async (ids, user, idDocumento) => {
  try {
    if (ids.length > 0) {
      await setDoc(doc(db, "fotos", idDocumento), {
        id: idDocumento,
        idFotos: ids,
        userEmail: user
      });

    } else { console.log("Sin fotos") }
  } catch (e) {
    console.error("Error de carga ", e);
  }

}


export { cargaImagen, cargaDocumento }
































































