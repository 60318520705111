

const IconCamera = (props) => {
  
  return (<svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    
    viewBox="0 0 100 100"
    {...props}
  >
    <path
 
      
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={5}
      d="M84.999 30H69.99h.009l-2.763-5.527C66.006 22.014 62.751 20 60 20H39.999c-2.751 0-6.006 2.014-7.235 4.474L30 30H14.999A5 5 0 0 0 10 35.001v39.998A5 5 0 0 0 15.001 80h70A5 5 0 0 0 90 74.999V35c0-2.76-2.239-5-5.001-5zM50.002 70C40.339 70 32.5 62.167 32.5 52.504 32.5 42.839 40.339 35 50.002 35 59.666 35 67.5 42.839 67.5 52.504 67.5 62.167 59.666 70 50.002 70z"
    />
    
  </svg>)
}


export default IconCamera