import { useState, useEffect } from "react"
import { ProgressBar,Modal } from 'react-bootstrap'

const ModalSubida = (props) => {
    const [show, setShow] = useState(true)
    const [contadorSubida, setContadorSubida] = useState(0)

    props.handleCount(setContadorSubida);



    return (<>


      <Modal show={show}>
        <Modal.Header closeButton>
          <Modal.Title>cargando fotos...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <ProgressBar variant="info" animated now={Math.round(contadorSubida * 100 / props.largo)} label={Math.round(contadorSubida * 100 / props.largo) + '%'} />
        </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
      </Modal>
    
     </>)

}

export default ModalSubida;