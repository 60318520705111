import './style/DragArea.css'
import React, { useState,useEffect  } from 'react'
import { Container, Row } from 'react-bootstrap';
import IconCamera from './icons/IconCamera';
import IconDelete from './icons/IconDelete'
import PopUp from './PopUp';
import ModalSubida from './ModalSubida';
import WarningAlertFotos from './WarningAlertFotos'
import ModalDropZone from './ModalDropZone';
import { cargaImagen, cargaDocumento } from '../services/firebase'

const timeStamp = new Date().getTime();
const DragArea = (props) => {
  const [images, setImages] = useState([])
  const [alerta, setAlerta] = useState("")
  const idDocumento = props.email + '-' + timeStamp
  const cFotos = props.cFotos;
  const idProducto = props.idProducto;
  let setContador;
  var screenWidth = window.screen.width;

  const unirImagenes = img => setImages(images => images.concat(...img))

  var handleClick = () => {
    document.getElementById('add-new-photo').click()
  }
  
  var handleChange = e => {
    let image = [];
    const files = e.target.files;
    if (files.length <= cFotos) {
      for (var i = 0; i < files.length; i++) {
        image.push({ id: `${files[i].name}_${new Date().getTime()}`, file: files[i], url: URL.createObjectURL(files[i]) })
        
      }
      setImages(images.concat(image))
      props.setcantidadFotos(props.cantidadFotos+files.length)
    } else {
      alert("Debe subir máximo " + cFotos + " fotos")
    }
  }
  if (images.length > cFotos) {
    setImages(images.slice(0, cFotos))
    alert("Debe subir máximo " + cFotos + " fotos")
  }


 
  const cargaImagenes = async () => {
    let ids = []
    let id = ''
    let contador = 0
    for(let i=0;i<images.length;i++){
      id = await cargaImagen(images[i].id, images[i].file, idDocumento);
      console.log("cargado")
      ids.push(id)
      
      setContador(contador++)
      
    }
    return ids;
  }
  const cargaDatos = async () =>{
    let ids = await cargaImagenes() 
    await cargaDocumento(ids,props.email,idDocumento);
    window.location.href = "https://valckpd.cl/carrito/?add-to-cart=" + idProducto + "&idUsuario=" +idDocumento +"/";
  }
  if (props.c === true) {
    cargaDatos()
  }


  var handleDeleteImg = id => {
    setImages(images.filter(image => image.id !== id))
    props.setcantidadFotos(props.cantidadFotos - 1)
    
  }

  const handleAction = file =>
    setImages(images.map(image => {
      if (file.id === image.id) {
        image.file = file.file;
        image.url = URL.createObjectURL(file.file)

      }
      return image;
    }
    ))

  
  props.setcantidadFotos(images.length)

    const handleCount = (setCount) =>{
      setContador = setCount;
    
    }



  var returnImages = () => {
    return images.map(file =>
    (
      <div className='card-container' key={file.id} >
        <div className='card' style={{ backgroundImage: 'url(' + file.url + ')', backgroundSize: 'contain', height: '100%', maxWidth: '100%' }}>
        </div>
        <button title='Eliminar foto' className='delete-button' onClick={() => handleDeleteImg(file.id)}>
          <IconDelete />
        </button>
        <div className='bottom-image'>
          <div >
            <PopUp file={file} action={handleAction}></PopUp>
          </div>
        </div>

      </div>

    ))
  }


  var imagenes = returnImages()
  if (Object.entries(imagenes).length === 0) imagenes = [' ', ' ']


  let handleAlert = (message, tipo) => {
    alerta = <WarningAlertFotos message={message} tipo={tipo} />
  }
  return (
    <>
      <Container>
        <Row>
          {imagenes}
        </Row>
      </Container>
      
      {props.c &&
            <ModalSubida handleCount={handleCount} largo={images.length} />

      }
      {(screenWidth>600)?
      <ModalDropZone unirImagenes={unirImagenes} setcantidadFotos={props.setcantidadFotos} cantidadFotos={props.cantidadFotos} imagesLength={images.length} />:
<abbr title='Agregar fotos'>
        <div className="add-photo" onClick={handleClick}  >
          <div className="add-photo-button">
            {/* <IconCamera width={50} fill='#fff' /> */}
            <p>Subir fotos</p>
          </div>
          <input type="file" onChange={handleChange} multiple id="add-new-photo" />
        </div>
      </abbr>
      }
      
    </>
  )
}
export default DragArea;
