const IconCut = props => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width="1em"
      height="1em"
      {...props}
    >
      <path d="M20.071 94.928A17.565 17.565 0 0 1 7.647 64.941a17.973 17.973 0 0 1 24.839 0 17.569 17.569 0 0 1-12.415 29.987Zm-.006-27.435a9.863 9.863 0 0 0-6.972 16.839 10.1 10.1 0 0 0 13.948 0 9.868 9.868 0 0 0-6.976-16.839Z" />
      <path d="M27.04 64.94 86.9 5.08l5.446 5.445-59.86 59.86zM79.929 94.918a17.56 17.56 0 0 1-12.421-29.981 17.974 17.974 0 0 1 24.837 0 17.563 17.563 0 0 1-12.416 29.981Zm0-27.43a9.863 9.863 0 0 0-6.975 16.839 10.106 10.106 0 0 0 13.945 0 9.867 9.867 0 0 0-6.97-16.839Z" />
      <path d="m7.647 10.518 5.447-5.446 59.86 59.863-5.445 5.447z" />
    </svg>
  )
  
  export default IconCut;