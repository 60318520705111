
import React, { useCallback,useState,useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import './style/DropZone.css'
const DropArea = (props) => {

  const onDrop =  useCallback(acceptedFiles => {
   props.unirFotos(acceptedFiles)
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const messageInactive = <p>Haz click o suelta tus fotos aqui para subirlas </p>
  const messageActive = <p>Suelte sus fotos aquí</p>
 
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      { 
        isDragActive ?
          <div className='dropZone__activate'>
            {messageActive}
          </div> :
          <div className='dropZone__inactivate'>
            {messageInactive}
          </div>
      }
    </div>
  )

}

export default DropArea;