import Navbarr from "./Navbar";
import DragArea from "./DragArea";
import { useState } from 'react';
const App = () => {
    const [click, setClick] = useState(false)
    const [cantidadFotos, setCantidadFotos] = useState(0)
    const [email, setEmail] = useState("")
    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);

    var cFotos = urlParams.get('cFotos') || 0;
    var idProducto = urlParams.get('id')
    return (<>
            <Navbarr s={setClick} setEmail={setEmail} cantidadFotos={cantidadFotos} cFotos={cFotos}/>
            <DragArea s={setClick} c={click} email={email}  cFotos={cFotos} idProducto={idProducto} setcantidadFotos={setCantidadFotos} cantidadFotos={cantidadFotos}/>
            </>
    )

}

export default App;