import { useState } from 'react';
import {  Button, Modal } from 'react-bootstrap';
import "./style/ModalAuth.css"
const ModalAuth = (props)=> {
    const [show, setShow] = useState(true);
    const [email,setEmail] = useState("")
    const [emailValido,setEmailValido] = useState(false)
    const validador = /^(([^<>()[\],;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    const mensajeModalAuth = <p>Por favor ingrese su email para vincularlo con su pedido</p>;
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const validadorMail = email => validador.test(email) ? true: false;
    const handleChange =  event => {
      setEmail(event.target.value)
      validadorMail(email) ? setEmailValido(true) : setEmailValido(false)
    }
    const handleClick = () =>{
      props.email(email)
      props.s(true)      
      handleClose()
    }

    const obsEmail = () => emailValido?<Button variant="primary" onClick={handleClick}>Confirmar</Button>:<Button variant="primary" disabled>Confirmar</Button>
    var boton = obsEmail(emailValido)

    return (
      <>
       
  
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header >
            <Modal.Title>Correo electrónico </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {mensajeModalAuth}
            <input type="text" onChange={handleChange} /> 
          </Modal.Body>
          <Modal.Footer>
            {boton}            
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  export default ModalAuth;