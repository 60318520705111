import { useState } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import IconCut from './icons/IconCut';
import './style/PopUp.css'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./style/cro.css"

const PopUp = props => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [image, setImage] = useState(props.file.url);
  const [cropper, setCropper] = useState();
  const getCropData = () => {
    if (typeof cropper !== "undefined") {

      cropper.getCroppedCanvas().toBlob(blob => {
        var fileAux = { id: props.file.id,  file: blob }
        props.action(fileAux)
        handleClose()
      }
      )
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className='editar-icon-container' onClick={handleShow}>
             <abbr title='Recortar foto'><button className='editar-icon'><IconCut  /></button> </abbr>
            </div>
          </Col>
        </Row>
      </Container>



      <Modal size="xl" show={show} onHide={handleClose} dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <Modal.Title>Editor de Fotos</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
     
          <div >
            <div className="box" style={{ width: "70%", float: "left" ,display: "flex",
    justifyContent: "center",
    alignItems: "center",marginTop:"20px"}} >

              <Cropper
                style={{ width: "50%" }}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={100}
                minCropBoxWidth={100}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>


            <div className="padre-preview" >
            
              <div className="box preview" style={{ width: "100%", float: "right" }}>
              <h1>Imagen previa</h1>
              <div>
                <div
                  className="img-preview"
                  style={{ width: "100%", float: "right", height: "300px" }}
                />
              </div></div>




            </div>
            <br style={{ clear: "both" }} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={getCropData} >
            Aplicar cambios
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PopUp;  